import styled from "styled-components";

import { breakpoint } from "helpers/shared/breakpoint";

export const TooltipContainer = styled.span`
  .__react_component_tooltip {
    max-width: 275px;
    width: 100%;
    border-radius: 8px;
    padding: 8px;
    font-size: ${({ theme }) => theme.size.caption};
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    line-height: 16px;

    ${breakpoint("xs", "md")`
      max-width: 190px;
      padding: 10px;
    `}

    &::before {
      content: none;
    }
  }
`;

export const Container = styled.div`
  display: inline-block;
`;
