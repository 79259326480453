import moment from "moment";

import { KycStatus } from "types/BETypes";
import { CardForm, CardStatus } from "types/CardShipping";
import { EStates, EStatesShort, StateSelectOptions } from "constants/shared";
import { getPhoneNumberOrNull } from "helpers";
import {
  EKycResponseType,
  KYCProfileFormType,
} from "components/CompanyBankAccountComponents/KYCVerificationWidget";
import { PaidCardShippingDetailsFormType } from "components/CompanyBankAccountComponents/PaidCardShippingDetailsForm";

import {
  BankCardResDto,
  IssueBankCardReqDto,
  KycVerifyReqDto,
  UserResponseDto,
} from "utils/swagger_react_query";

import { FAILED_KYC_USER_DATA_LOCAL_STORAGE_KEY } from "./constants";

export const userDetailsToKycProfileFormInitialValues = (
  userDetails: UserResponseDto | undefined | null,
): KYCProfileFormType => {
  const state = StateSelectOptions.find((state) => state.value === userDetails?.state) || {
    label: EStates.AK,
    value: EStatesShort.AK,
  };

  const result: KYCProfileFormType = {
    firstName: userDetails?.firstName || "",
    lastName: userDetails?.lastName || "",
    phone: getPhoneNumberOrNull(userDetails?.phone || "")!,
    address: userDetails?.address || "",
    city: userDetails?.city || "",
    state: state,
    zip: userDetails?.zip || "",
    birthDate: userDetails?.birthDate ? new Date(userDetails?.birthDate) : (null as any),
    suite: userDetails?.suite || "",
    socialSecurityNumber: userDetails?.socialSecurityNumber || "",
  };

  return result;
};

export const profileFormValuesToKycRequestPayload = (
  values: KYCProfileFormType,
): KycVerifyReqDto => {
  const result: KycVerifyReqDto = {
    phone: values.phone,
    address: values.address,
    city: values.city,
    state: values.state.value,
    zip: values.zip,
    birthDate: moment(values.birthDate).toISOString(),
    suite: values.suite || undefined,
    socialSecurityNumber: values.socialSecurityNumber,
  };

  return result;
};

export const userDetailsToShippingDetailsFormInitialValues = (
  userDetails: UserResponseDto | undefined | null,
): PaidCardShippingDetailsFormType => {
  const state = StateSelectOptions.find((state) => state.value === userDetails?.state) || {
    label: "",
    value: "",
  };

  const result: PaidCardShippingDetailsFormType = {
    firstName: userDetails?.firstName || "",
    lastName: userDetails?.lastName || "",
    address: userDetails?.address || "",
    city: userDetails?.city || "",
    state: state,
    zip: userDetails?.zip || "",
    suite: userDetails?.suite || "",
    phone: getPhoneNumberOrNull(userDetails?.phone || "")!,
  };

  return result;
};

export const shippingDetailsFormValuesToRequestPayload = (
  values: PaidCardShippingDetailsFormType,
): IssueBankCardReqDto => {
  const result: IssueBankCardReqDto = {
    //use IssueBankCardReqDto to create new fields
    form: CardForm.PHYSICAL,
    shipping: {
      address: {
        addressLine1: values.address,
        addressLine2: values.suite || undefined,
        city: values.city,
        state: values.state.value,
        postalCode: values.zip,
        countryCode: "US",
      },
      recipientFirstName: values.firstName,
      recipientLastName: values.lastName,
      phoneNumber: values.phone,
    },
  };

  return result;
};

export const setFailedKycUserDataToLocalStorage = (data: KycVerifyReqDto) => {
  localStorage.setItem(FAILED_KYC_USER_DATA_LOCAL_STORAGE_KEY, JSON.stringify(data));
};

export const getFailedKycUserDataFromLocalStorage = (): KycVerifyReqDto | undefined => {
  const data = localStorage.getItem(FAILED_KYC_USER_DATA_LOCAL_STORAGE_KEY);
  return data ? JSON.parse(data) : undefined;
};

export const removeFailedKycUserDataFromLocalStorage = () => {
  localStorage.removeItem(FAILED_KYC_USER_DATA_LOCAL_STORAGE_KEY);
};

export const getCombinedUserDataWithFailedKycData = (
  userData: UserResponseDto | undefined | null,
  failedKycData: KycVerifyReqDto | undefined,
): UserResponseDto | undefined | null => {
  if (!failedKycData || !userData) return userData;

  const result: UserResponseDto = {
    ...userData,
    phone: failedKycData.phone,
    address: failedKycData.address || null,
    city: failedKycData.city || null,
    state: failedKycData.state || null,
    zip: failedKycData.zip || null,
    birthDate: failedKycData.birthDate || null,
    suite: failedKycData.suite,
    socialSecurityNumber: failedKycData.socialSecurityNumber || null,
  };

  return result;
};

export const getKycResponseType = (
  kycStatus?: KycStatus,
  kycVerificationPayload?: KycVerifyReqDto,
  KycVerificationPreviousPayload?: KycVerifyReqDto,
): EKycResponseType | undefined => {
  if (!kycStatus) return undefined;
  const isDataChanged =
    JSON.stringify(KycVerificationPreviousPayload) !== JSON.stringify(kycVerificationPayload);

  if (kycStatus === KycStatus.ACCEPTED) {
    return EKycResponseType.SUCCESS;
  }

  if (
    [
      KycStatus.PROVIDER_FAILURE,
      KycStatus.REJECTED,
      KycStatus.UNVERIFIED,
      KycStatus.VENDOR_ERROR,
    ].includes(kycStatus as KycStatus)
  ) {
    return EKycResponseType.ERROR;
  }

  if (
    [KycStatus.PENDING, KycStatus.PROVISIONAL, KycStatus.REVIEW].includes(kycStatus as KycStatus)
  ) {
    return EKycResponseType.VERIFICATION_IS_TAKING_TOO_LONG;
  }

  if (!isDataChanged && kycStatus === KycStatus.REJECTED) {
    return EKycResponseType.INCORRECT_INFO;
  }
};

export const isPhysicalCardActiveOrUnactivated = (card: BankCardResDto) =>
  card.form === CardForm.PHYSICAL &&
  [CardStatus.UNACTIVATED, CardStatus.ACTIVE].includes(card.cardStatus as CardStatus);
