import { AlertSquareRounded } from "assets/svg";
import styled from "styled-components";

import { H2, PrimaryButtonIconed } from "uikit";

import { Container as BankAccountInfoRowContainer } from "../BankAccountInfoRows/styles";

export const Wrapper = styled.div`
  padding-bottom: 25px;

  & > p:nth-child(1) {
    font-size: ${({ theme }) => theme.size.title};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    color: ${({ theme }) => theme.colors.textAlt};
    margin-bottom: 14px;
  }
`;

export const StyledH2 = styled(H2)`
  margin-bottom: 24px;
`;

export const ConnectBankBtn = styled(PrimaryButtonIconed)`
  width: auto;
  font-size: ${({ theme }) => theme.size.body2};
`;

export const BankAccountsListWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BankAccountListRow = styled.div`
  &:last-of-type ${BankAccountInfoRowContainer} {
    margin-bottom: 0;
  }
`;

export const EmptyStateContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.sectionsContainerAlt2};
  height: 240px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
`;

export const ESIcon = styled(AlertSquareRounded)`
  margin-bottom: 21px;

  path {
    stroke: ${({ theme }) => theme.colors.textAlt4};
  }
`;

export const ESTitle = styled.p`
  font-size: ${({ theme }) => theme.size.subtitle};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  color: ${({ theme }) => theme.colors.text};
  line-height: 23.4px;
`;

export const ESMessage = styled.p`
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.textInactiveAlt2};
  line-height: 20.8px;
  margin-bottom: 16px;
`;
