import styled from "styled-components";

import { breakpoint } from "helpers";
import TransactionsListBanking from "components/TransactionsListBanking";

import {
  NoBorderButton,
  PageTitleMargined,
  PrimaryButtonIconed,
  SecondaryButtonIconed,
  SectionInner,
  SectionInnerTitle,
} from "uikit";
import { iconedButtonMixin } from "uikit/Buttons/Buttons";

import BankCardStatus from "../../shared/components/BankCardStatus";

//Bank account section

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  height: 100%;
`;

export const MonthlyStatementsLinkButton = styled(NoBorderButton)`
  ${iconedButtonMixin};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  font-size: ${({ theme }) => theme.size.body2};
  display: flex;
  height: auto;
  gap: 8px;
`;

export const TransferFundsButton = styled(PrimaryButtonIconed)`
  width: auto;
  svg {
    path {
      fill: none;
      stroke: ${({ theme }) => theme.colors.primaryText};
    }
  }

  &:disabled {
    svg {
      path {
        fill: none;
        stroke: ${({ theme }) => theme.colors.primaryTextDisabled};
      }
    }
  }

  ${breakpoint("xs", "xlm")`
    width: 100%;
    max-width: 100%;
  `}
`;

//Cards section

export const CardsContainer = styled.div``;

export const CardItem = styled(SectionInner)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;

  ${breakpoint("xs", "md")`
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  `}
`;

export const CardInfo = styled.div`
  ${breakpoint("xs", "md")`
    width: 100%;
  `}
`;

export const CardTitle = styled(SectionInnerTitle)`
  font-size: ${({ theme }) => theme.size.body1};
`;

export const StyledBankCardStatus = styled(BankCardStatus)`
  ${breakpoint("xs", "md")`
    width: 100%;
    justify-content: center;
    margin-top: 16px;
  `}
`;

export const CIBottom = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  ${breakpoint("xs", "md")`
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;  
  `}
`;

export const CardNumber = styled.div`
  font-size: ${({ theme }) => theme.size.subtitle};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  line-height: 1.77;
  margin-right: 32px;

  ${breakpoint("xs", "md")`
    margin-right: 0;
    margin-top: 16px;
  `}
`;

export const CardButtonsContainer = styled.div`
  ${breakpoint("xs", "md")`
    width: 100%;
  `}
`;

export const CardButton = styled(SecondaryButtonIconed)`
  border-width: 1px;
  padding: 10px 12px;
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  line-height: 1.42;
  height: 40px;
  min-height: 40px;

  svg {
    margin-left: 8px;
    path {
      stroke: ${({ theme }) => theme.colors.secondaryButtonText};
    }
  }

  ${breakpoint("xs", "md")`
    margin-top: 16px;
    width: 100%;
    max-width: 100%;
  `}
`;

//Transactions section

export const TransactionsContainer = styled.div``;

export const StyledTransactionsList = styled(TransactionsListBanking)``;

export const AddAccountButton = styled(PrimaryButtonIconed)`
  width: auto;
  min-width: auto;

  ${breakpoint("xs", "md")`
    width: 100%;
    margin: 16px auto 0 auto;
  `}
`;
