import { CardForm } from "types/CardShipping";
import { APPLICATION_NAME_UPPERCASE } from "constants/systemConstants";
import { EComprehensibleCardStatus } from "helpers/assets/bankCards";
import {
  PinModalType,
  ReissueCardModalStep,
} from "pages/Banking/employee/EmployeeCardsDetailsPage/constants";

export const bankingPages = {
  "en-US": {
    admin: {},
    employee: {
      index: {
        account_section: {
          transfer_funds_button: "Transfer Funds",
          monthly_statements_button: "Monthly Statements",
        },

        cards_section: {
          title: "Your Cards",
        },

        transactions_section: {
          title: "Recent Transactions",
        },

        modals: {
          request_virtual_card: {
            title: "Card Ordered",
            message:
              "Your physical card has been successfully shipped! While you wait for its arrival, you can activate a digital card now and start using it immediately.",
            main_button: "Request a Digital Card",
          },
        },
      },

      monthly_statements_page: {
        statements_year_label: "Statements Year:",
        new_mark: "New",
        columns: {
          statement_period: "Statement Period",
        },
        empty_state: {
          title: "No Statements Found",
          message: "There are no statements available for the selected period",
        },
      },

      cards_details_page: {
        tab_labels: {
          [CardForm.PHYSICAL]: "Physical Card",
          [CardForm.VIRTUAL]: "Virtual Card",
        },

        card_details_component: {
          title: {
            [CardForm.PHYSICAL]: "Physical Card",
            [CardForm.VIRTUAL]: "Virtual Card",
          },
          card_number_label: "Card Number",
          card_view: {
            pan_code: "Card Number",
            exp_date: "Valid Thru",
            cvv: "CVC",
            reveal_card_details_btn: "Reveal Card Details",
            hide_card_details_btn: "Hide Card Details",
          },
          action_btns: {
            activate_card: "Activate a Card",
            set_pin: "Set PIN",
            reset_pin: "Reset PIN",
            reissue_card: "Reissue Card",
            cancel_card: "Cancel Card",
          },
          set_pin_message: {
            title: "Set a PIN",
            text: "To use your card for all types of transactions, please set your PIN",
          },
          reissued_expired_card_message: {
            title: "New card is issued",
            text: "You can still use your current card before the expiration date or before you activate the new one.",
          },
          reissued_lost_stolen_card_message: {
            title: "New card is issued",
            text: "It will replace your current card once you activate the new one.",
          },
        },
        request_card_component: {
          title: "Request a Card",
          message: {
            [CardForm.PHYSICAL]: "Would you like to confirm issuing a physical card?",
            [CardForm.VIRTUAL]: "Would you like to confirm issuing a virtual card?",
          },
          request_card_btn: {
            [CardForm.PHYSICAL]: "Continue Card Issuing",
            [CardForm.VIRTUAL]: "Request Card",
          },
        },
        pin_modal_component: {
          [PinModalType.SET_PIN]: {
            title: "Create a PIN",
            message: "To activate your virtual card, please create a PIN code",
          },
          [PinModalType.RESET_PIN]: {
            title: "Reset a PIN",
            message: "If you forgot your PIN or want to change it, please create a new one below",
          },
        },
        activate_physical_card_modal: {
          title: "Activate your Card?",
          message:
            "To activate your card, you need to have it in hand and provide the current PIN, Card Number, and CVV code.",
        },
        cancel_card_modal: {
          title: "Cancel Card",
          message: "Are you sure you want to cancel your card?",
          cancel_card: "Cancel Card",
        },
        reissue_card_modal: {
          [ReissueCardModalStep.REASON]: {
            title: "Reissue Card",
            message:
              "Are you certain that you would like to request a replacement for your bank card?",
            reason_field: {
              label: "Reissue Reason",
              placeholder: "Select a reason",
            },
          },
          [ReissueCardModalStep.SHIPPING_DETAILS]: {
            title: "Reissue Card",
            message:
              "Please complete the address delivery form in order to receive your bank card.",
          },
          success: {
            title: "Card Reissued",
            message: "Your card has been successfully reissued!",
          },
        },
      },

      transfer_funds_page: {
        payment_details_section: {
          title: "Payment Details",
          empty_state: {
            message: "To proceed with this funds transfer, connect your bank account.",
            connect_bank_account_btn: "Connect Bank Account",
          },
          transfer_funds_form: {
            from: "From Bank Account",
            to: "To Bank Account",
            amount: "Amount",
            description: "Description",
            submit_btn: "Transfer Funds",
            input_errors: {
              amount_higher_than_balance:
                "There is not enough balance available to complete the transfer.",
            },
          },
          modals: {
            labels: {
              from: "From",
              to: "To",
              amount: "Amount",
            },
            transfer_confirmation: {
              title: "Transfer Funds",
              message: "Are you sure you want to transfer funds?",
            },
            transfer_success: {
              title: "Transfer Sent",
              message:
                "Your fund transfer is currently being processed. You can track the status of your transaction.",
            },
            //
            manual_bank_account_additional_info_modal: {
              title: "To submit a transaction please provide the following details",
              bank_name: "Bank Name",
              bank_holder_name: "Bank Holder Name",
            },
          },
        },
      },

      shipping_page: {
        submit_button: "Next",
        title: "Banking",
      },
    },

    shared: {
      transactions_mfa_modal: {
        title: "Enable Two-Factor Authentication",
        message:
          "For your security, please enable Two-Factor Authentication (2FA) to proceed with transferring funds.",
        enable_btn: "Enable 2FA",
      },
      components: {
        bank_account_info: {
          title: `${APPLICATION_NAME_UPPERCASE} Bank Account`,
          subtitle: "Balance",
          routing_number_label: "Routing Number",
          account_number_label: "Account Number",
        },

        bank_cards: {
          card_list_buttons: {
            [CardForm.PHYSICAL]: {
              [EComprehensibleCardStatus.ACTIVE]: "Manage Card",
              [EComprehensibleCardStatus.ACTIVE_WITHOUT_PIN]: "Manage Card",
              [EComprehensibleCardStatus.EMPTY]: "Continue Card Issuing",
              [EComprehensibleCardStatus.INACTIVE]: "Activate a Card",
              [EComprehensibleCardStatus.CANCELED]: "Manage Card",
              [EComprehensibleCardStatus.EXPIRED]: "Manage Card",
            },
            [CardForm.VIRTUAL]: {
              [EComprehensibleCardStatus.ACTIVE]: "Manage Card",
              [EComprehensibleCardStatus.ACTIVE_WITHOUT_PIN]: "Set a PIN",
              [EComprehensibleCardStatus.EMPTY]: "Request a Card",
              [EComprehensibleCardStatus.INACTIVE]: "Activate a Card",
              [EComprehensibleCardStatus.CANCELED]: "Manage Card",
              [EComprehensibleCardStatus.EXPIRED]: "Manage Card",
            },
          },
        },
      },
    },
  },
};

export default bankingPages;
