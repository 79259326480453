import { FC, ReactNode } from "react";
import { Trans } from "react-i18next";
import { CopyIcon, EyeClosed, EyeOpen } from "assets/svg";

import { getHiddenNumber, getSeparatedNumber } from "helpers";

import { SectionAlt, SectionInner, SectionInnerTitle, SectionTitle } from "uikit";

import { IBankAccountNumber } from "./types";
import {
  BAButtons,
  BACopyButton,
  BAItem,
  BankAccountAmount,
  BankAccountInfoButtonContainer,
  BankAccountInfoContainer,
  BANumber,
  BANumbersContainer,
  BANumberWrapper,
  BAShowButton,
  BATitle,
  Container,
  StyledSectionInnerRow,
} from "./styles";

interface Props {
  className?: string;
  amount: number | string;
  items: IBankAccountNumber[];
  additionalContent?: ReactNode;
}

const BankAccountInfo: FC<Props> = ({ className, amount, items, additionalContent }) => {
  const translationPrefix = `banking_pages.shared.components.bank_account_info`;

  const renderBAIContainer = (data: IBankAccountNumber) => {
    return (
      <BAItem>
        <BANumberWrapper>
          <BATitle>{data.title}</BATitle>
          <BANumber>
            {data.isHidden
              ? getHiddenNumber(data.number || "", 3)
              : getSeparatedNumber(data.number || "", 3)}
          </BANumber>
        </BANumberWrapper>
        <BAButtons>
          <BAShowButton onClick={data.onShowToggle}>
            {data.isHidden ? <EyeClosed /> : <EyeOpen />}
          </BAShowButton>
          <BACopyButton id={data.id} copyContent={data.number}>
            <CopyIcon />
          </BACopyButton>
        </BAButtons>
      </BAItem>
    );
  };

  return (
    <Container className={className}>
      <SectionAlt>
        <SectionTitle>
          <Trans i18nKey={`${translationPrefix}.title`} />
        </SectionTitle>
        <SectionInner>
          <StyledSectionInnerRow>
            <BankAccountInfoContainer>
              <SectionInnerTitle>
                <Trans i18nKey={`${translationPrefix}.subtitle`} />
              </SectionInnerTitle>
              <BankAccountAmount sum={Number(amount) / 100} separateCurrencyPrefix={true} />
              <BANumbersContainer>
                {items.map((item) => (
                  <>{renderBAIContainer(item)}</>
                ))}
              </BANumbersContainer>
            </BankAccountInfoContainer>
            <BankAccountInfoButtonContainer>{additionalContent}</BankAccountInfoButtonContainer>
          </StyledSectionInnerRow>
        </SectionInner>
      </SectionAlt>
    </Container>
  );
};

export default BankAccountInfo;
