import { BankIconPlaceholder, LogoFull } from "assets/svg";
import styled from "styled-components";

import { RadioButton, RoundBorderContainer, UIModal } from "uikit";

export const StyledUIModal = styled(UIModal)`
  width: 560px !important;
`;

export const MethodsList = styled.div`
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 32px;
`;

export const MethodItem = styled(RoundBorderContainer)`
  display: flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;

  &.active {
    border-color: ${({ theme }) => theme.colors.primary};
  }
`;

export const MethodItemRadioButton = styled(RadioButton)``;

export const PaidIcon = styled(LogoFull)`
  width: 68px;
  height: 42px;
`;

export const ExternalBankIcon = styled(BankIconPlaceholder)`
  width: 68px;
  height: 42px;
`;

export const MethodItemTitle = styled.p`
  font-size: ${({ theme }) => theme.size.subtitleLarge};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  line-height: 28px;
  margin-bottom: 4px;
`;

export const MethodItemDescription = styled.p`
  font-size: ${({ theme }) => theme.size.body2};
  color: ${({ theme }) => theme.colors.textAlt};
  line-height: 18.2px;
`;
