import { IModalProps } from "uikit/Modal/types";

import { UserResponseDto } from "utils/swagger_react_query";

import { Events } from "./events";

export type TAppEvents = {
  SetGlobalModal: IModalProps;
  RoleHasUpdated: { roleHasUpdated: boolean };
  PromptsWereDisabled: { promptsWereDisabled: boolean };
  CloseGlobalModal: any;
  SetValidationModal: {
    userInfo: UserResponseDto | null;
    exitDate: string | null;
    onClose?: () => void;
  };
};

export const AppEvents = new Events<TAppEvents>();
