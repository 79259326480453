import { setSalsa } from "./salsaData";
import {
  setCompanyData,
  setRouterUserRequestInProgress,
  setUserMetaData,
  setWorkerKycOnboardingInProgress,
  updateUserMetaDataLastActiveRole,
} from "./userMetaData";

const actions = {
  userMetaData: {
    setUserMetaData,
    setCompanyData,
    updateUserMetaDataLastActiveRole,
    setWorkerKycOnboardingInProgress,
    setRouterUserRequestInProgress,
  },
  salsaData: {
    setSalsa,
  },
};

export default actions;
