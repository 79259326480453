import { createAction } from "@reduxjs/toolkit";
import { ISalsa } from "store/types";

import {
  CompanyResponseDto,
  UpdateLastActiveRoleDto,
  UserResponseDto,
} from "utils/swagger_react_query";

import {
  SET_COMPANY_DATA,
  SET_ROUTER_USER_REQUEST_IN_PROGRESS,
  SET_SALSA,
  SET_WORKER_KYC_ONBOARDING_IN_PROGRESS,
  USER_METADATA_SET_METADATA,
  USER_METADATA_UPDATE_LAST_ACTIVE_ROLE,
  USER_METADATA_UPDATE_PERSONAL_EMAIL,
} from "../constants/ActionTypes";

export const setUserMetaData = createAction<
  UserResponseDto | null | undefined,
  typeof USER_METADATA_SET_METADATA
>(USER_METADATA_SET_METADATA);

export const updateUserMetaDataLastActiveRole = createAction<
  UpdateLastActiveRoleDto["role"],
  typeof USER_METADATA_UPDATE_LAST_ACTIVE_ROLE
>(USER_METADATA_UPDATE_LAST_ACTIVE_ROLE);

export const setCompanyData = createAction<CompanyResponseDto | null, typeof SET_COMPANY_DATA>(
  SET_COMPANY_DATA,
);

export const updateUserPersonalEmail = createAction<
  UserResponseDto["personalEmail"],
  typeof USER_METADATA_UPDATE_PERSONAL_EMAIL
>(USER_METADATA_UPDATE_PERSONAL_EMAIL);

export const setSalsa = createAction<ISalsa | null, typeof SET_SALSA>(SET_SALSA);

export const setWorkerKycOnboardingInProgress = createAction<
  boolean,
  typeof SET_WORKER_KYC_ONBOARDING_IN_PROGRESS
>(SET_WORKER_KYC_ONBOARDING_IN_PROGRESS);

export const setRouterUserRequestInProgress = createAction<
  boolean,
  typeof SET_ROUTER_USER_REQUEST_IN_PROGRESS
>(SET_ROUTER_USER_REQUEST_IN_PROGRESS);
