import styled, { css } from "styled-components";

import { breakpoint } from "helpers/shared/breakpoint";

export const ContainerBase = styled.div`
  display: inline-block;
`;

export const ContentContainerMixin = css`
  display: flex;
  flex-direction: column;
  flex: 1;

  ${breakpoint("xs")`
    padding: 0 ${({ theme }) => theme.contentPadding.xs}px;
  `}

  ${breakpoint("sm")`
    padding: 0 ${({ theme }) => theme.contentPadding.sm}px;
  `}

  ${breakpoint("md")`
    padding: 0 ${({ theme }) => theme.contentPadding.md}px;
  `}

  ${breakpoint("lg")`
    padding: 0 ${({ theme }) => theme.contentPadding.lg}px;
  `}

${breakpoint("xlm")`
    padding: 0 ${({ theme }) => theme.contentPadding.xlm}px;
  `}

  ${breakpoint("xl")`
    padding: 0 ${({ theme }) => theme.contentPadding.xl}px;
  `}

  ${breakpoint("xxl")`
    padding: 0 ${({ theme }) => theme.contentPadding.xxl}px;
  `}
`;

export const ContentContainer = styled.div`
  ${ContentContainerMixin};
`;

export const FullWidthOnMobileContainer = styled.div`
  ${breakpoint("xs", "lg")`
    padding: 0;
  `}

  ${breakpoint("lg")`
    padding: 0 ${({ theme }) => theme.contentPadding.lg}px;
  `}

  ${breakpoint("xl")`
    padding: 0 ${({ theme }) => theme.contentPadding.xl}px;
  `}

  ${breakpoint("xxl")`
    padding: 0 ${({ theme }) => theme.contentPadding.xxl}px;
  `}
`;

export const MobileContent = styled(ContainerBase)`
  ${breakpoint("lg")`
    display: none;
  `}
`;

export const DesktopContent = styled(ContainerBase)`
  ${breakpoint("xs", "lg")`
    display: none;
  `}
`;

export const MiddleContent = styled(ContainerBase)`
  ${breakpoint("xs", "md")`
    display: none;
  `}
`;

export const BelowMiddleContent = styled(ContainerBase)`
  ${breakpoint("md")`
    display: none;
  `}
`;

export const XlContent = styled(ContainerBase)`
  ${breakpoint("xs", "xl")`
    display: none;
  `}
`;

export const RoundBorderContainerMixin = css`
  border: 1px solid ${({ theme }) => theme.colors.tableBorder};
  border-radius: 8px;
  padding: 16px;
  box-sizing: border-box;
`;

export const RoundBorderContainer = styled.div`
  ${RoundBorderContainerMixin}
`;

export const Section = styled.div`
  ${RoundBorderContainerMixin}
  background: ${({ theme }) => theme.colors.sectionContainer};
  margin-bottom: 16px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const SectionAlt = styled.div`
  background: ${({ theme }) => theme.colors.sectionAltContainer};
  border-radius: 4px;
  padding: 24px;
  margin-bottom: 24px;
  box-sizing: border-box;

  &:last-of-type {
    margin-bottom: 0;
  }

  ${breakpoint("xs", "xlm")`
    padding: 16px;
    margin-bottom: 16px;
  `}
`;

export const SectionAltRounded = styled(SectionAlt)`
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.sectionsContainerAlt2};
`;

export const SectionInner = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.contentBg};
  border-radius: 4px;
  padding: 24px 20px;
  margin-bottom: 16px;
  box-sizing: border-box;

  &:last-of-type {
    margin-bottom: 0;
  }

  ${breakpoint("xs", "xlm")`
    padding: 12px;
    margin-bottom: 12px;
  `}
`;

export const SectionInnerRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${breakpoint("xs", "xlm")`
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  `}
`;

export const frameContainerBaseMixin = css`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 100%;

  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  & > * {
    z-index: 1;
    //pointer-events: none;
  }

  iframe {
    max-width: 100%;
    overflow: auto;
  }
`;

export const FrameContainer = styled.div`
  ${frameContainerBaseMixin}
`;

/* For maintaining flex positionig in some cases */
export const Dud = styled.span`
  visibility: hidden;
`;
