import { FC, useCallback, useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { useRouteHelpers } from "routes/helpers";
import routes from "routes/routes";
import reduxActions from "store/actions";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { routerUserRequestInProgressSelector } from "store/selectors";

import { getRedirectionLink, saveRedirectionLink } from "services/linkRedirector";
import { UserRole } from "types/BETypes";
import { Pages, permissions as permissionsList } from "permissions";
import {
  getShouldAdminCompleteSignUp,
  getShouldEmployeeCompleteSignUp,
} from "helpers/shared/guardRedirect";
import { useEventsListener } from "hooks";
import useAuth from "hooks/useAuth";
import { useConnectSalsa } from "hooks/useConnectSalsa";
import ActivityTracker from "components/ActivityTracker";
import SalsaTokenRefresher from "components/SalsaTokenRefresher";

import { FullScreenLoader } from "uikit";

import { CompanyResponseDto, UserResponseDto } from "utils/swagger_react_query";

import { IRoutePropsExtended } from "../../types";

const ProtectedRoute: FC<IRoutePropsExtended> = (props) => {
  const { children, permissions, disableCondition } = props;

  const [userLoaded, setUserLoaded] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<UserResponseDto | null>(null);
  const [currentCompany, setCurrentCompany] = useState<CompanyResponseDto | null>(null);
  const [userChecked, setUserChecked] = useState<boolean>(false); //Additional flag that determines if we need to redirect user before showing them a page content;
  const { getCurrentUser, getCurrentUserAndCompany } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [needDisable, setNeedDisable] = useState<boolean>(false);
  const { connectSalsa } = useConnectSalsa();
  const { getDefaultRoute, getDefaultAdminRoute, getDefaultEmployeeRoute } = useRouteHelpers();
  const dispatch = useAppDispatch();
  const routerUserRequestInProgress = useAppSelector(routerUserRequestInProgressSelector);

  useEventsListener<"RoleHasUpdated">(
    "RoleHasUpdated",
    ({ roleHasUpdated }) => {
      setNeedDisable(roleHasUpdated);
    },
    [],
  );

  const saveRedirect = () => {
    saveRedirectionLink(location.pathname + location.search);
  };

  const isPermitted = (user?: UserResponseDto | null, company?: CompanyResponseDto | null) => {
    if (needDisable) return true;
    if (!user) return false;

    const result =
      !disableCondition?.(user, company) &&
      (!permissions ||
        permissionsList[user?.lastActiveRole as UserRole]?.page[permissions as Pages]);
    return result;
  };

  const checkUserPermission = (
    user?: UserResponseDto | null,
    company?: CompanyResponseDto | null,
  ) => {
    if (!user?.userId || !isPermitted(user, company)) {
      navigate(routes.INSUFFICIENT_PERMISSIONS);
      return false;
    } else {
      setUserChecked(true);
      return true;
    }
  };

  const checkRedirects = (
    user?: UserResponseDto | null,
    company?: CompanyResponseDto | null,
    pathname?: string,
  ) => {
    if (!user?.lastActiveRole) {
      navigate(routes.INSUFFICIENT_PERMISSIONS);
      return;
    }

    const redirectionLink = getRedirectionLink(true);

    if (
      redirectionLink &&
      redirectionLink !== getDefaultAdminRoute() &&
      redirectionLink !== getDefaultEmployeeRoute() &&
      redirectionLink !== routes.ROOT
    ) {
      //TODO: Won't work on dev, because of double useEffect execution in React 18, fix later
      //It will redirect once, but second location.pathname will trigger this check again, and won't trigger this "if" anymore
      navigate(redirectionLink);
      return;
    } else if (
      user?.lastActiveRole !== UserRole.EMPLOYEE &&
      ["/", getDefaultEmployeeRoute(user, company)].includes(pathname || "")
    ) {
      navigate(getDefaultRoute(user, company));
      setUserChecked(true);
      return;
    } else if (
      user?.lastActiveRole === UserRole.EMPLOYEE &&
      ["/", getDefaultAdminRoute(user, company)].includes(pathname || "")
    ) {
      navigate(getDefaultRoute(user, company));
      setUserChecked(true);
      return;
    }

    checkUserPermission(user, company);
  };

  useEffect(() => {
    async function checkUser() {
      setUserChecked(false);
      const result = await getCurrentUserAndCompany();
      const user = result?.user;
      const company = result?.company;

      if (user && company) {
        const shouldAdminCompleteSignUp = getShouldAdminCompleteSignUp(user);
        const shouldEmployeeCompleteSignUp = getShouldEmployeeCompleteSignUp(user);

        if (shouldAdminCompleteSignUp) {
          saveRedirect();
          return navigate(routes.ADMIN_SIGN_UP);
        }
        if (shouldEmployeeCompleteSignUp) {
          saveRedirect();
          return navigate(routes.EMPLOYEE_SIGN_UP);
        }

        Sentry.setUser({
          id: user.userId,
          email: user.email,
          username: [user.firstName, user.lastName].join(" "),
          companyName: company.name,
          companyId: company.companyId,
          lastActiveRole: user.lastActiveRole,
          roles: user.companyRoles,
        });

        setCurrentUser(user);
        setCurrentCompany(company);
        checkRedirects(user, company, location?.pathname);
        await connectSalsa(user, true);
      } else {
        saveRedirect();
        setUserChecked(true);
      }
      setUserLoaded(true);
    }

    dispatch(reduxActions.userMetaData.setRouterUserRequestInProgress(true));
    checkUser();

    // Using [] does not trigger this hook on page redirects, so we use location.pathname as a dependency.
    // PAIDSW-155 requires us to keep company and user info up-to-date, so we need to request this data on every route change
    // If something goes wrong - remove it and try to find another way to solve the problem
  }, []);

  useEffect(() => {
    if (currentUser && currentCompany) {
      checkRedirects(currentUser, currentCompany, location?.pathname);
    }
  }, [currentUser]);

  useEffect(() => {
    if (userLoaded && userChecked) {
      if (!currentUser) {
        navigate(routes.SIGN_IN);
        return;
      }
      dispatch(reduxActions.userMetaData.setRouterUserRequestInProgress(false));
    }
  }, [userLoaded, userChecked]);

  return (
    <>
      {!userLoaded || !userChecked ? (
        <>
          <FullScreenLoader />
        </>
      ) : (
        <>
          {currentUser && (
            <>
              <ActivityTracker />
              <SalsaTokenRefresher />
              {children}
            </>
          )}
        </>
      )}
    </>
  );
};

export default ProtectedRoute;
