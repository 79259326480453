import { AttentionTriangleIcon } from "assets/svg";
import styled from "styled-components";

import { breakpoint } from "helpers/shared/breakpoint";

export const Wrapper = styled.div`
  ${breakpoint("xlm")`
      display: none;
   `}

  display: flex;
  align-items: center;
  width: 100%;
  background: ${({ theme }) => theme.colors.sectionsContainerAlt};
  padding: 16px;
  box-sizing: border-box;
`;

export const Icon = styled(AttentionTriangleIcon)`
  margin-right: 20px;
`;

export const Title = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  font-size: ${({ theme }) => theme.size.body1};
  line-height: 24px;
`;

export const Description = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-size: ${({ theme }) => theme.size.caption};
  line-height: 16px;
`;
