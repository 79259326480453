import styled from "styled-components";

import { breakpoint } from "helpers";
import MfaAuthenticationStatus from "components/MfaAuthenticationStatus";
import { SelectedPhoneContainer } from "components/MultiFactorAuthorization/components/sms/steps/VerifyCode/components/DefaultForm/styles";

import { PrimaryButton } from "uikit";

export const OptionsSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0;
`;

export const CountText = styled.p`
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.text};

  ${breakpoint("xs", "lg")`
    width: 100%;
  `}
`;

export const StyledMfaAuthenticationStatus = styled(MfaAuthenticationStatus)`
  margin-right: 16px;
`;

export const StyledPrimaryButton = styled(PrimaryButton)``;

export const CardAdditionContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SmsCardDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledSelectedPhoneContainer = styled(SelectedPhoneContainer)`
  border: none;
  background-color: ${({ theme }) => theme.colors.sectionsContainerAlt2};
`;
