import styled from "styled-components";

import { UserRole } from "types/BETypes";
import { breakpoint } from "helpers/shared/breakpoint";
import { Container as BackButtonContainer } from "components/BackButton/styles";
import Copyright from "components/Copyright";

import { Body3, ContentContainer, LinkTextSmallMixin } from "uikit";

export const menuWidth = 240;
export const mobileLogoHeightSmall = 24;
export const mobileLogoHeight = 28;

export const TCVPadding = 0;
export const TCHPadding = 0;
export const TCVMobilePadding = 12;
export const TCHMobilePadding = 16;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  color: ${({ theme }) => theme.colors.text};
  background-color: ${({ theme }) => theme.colors.contentBg};

  ${breakpoint("xs", "lg")`
    min-width: 360px;
    margin: 0 auto;
  `}
`;

export const TopMain = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: row;
  overflow: hidden;
`;

export const MBBottomLink = styled.a`
  ${LinkTextSmallMixin};
  display: block;
`;

export const ContentBlock = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-position: bottom right;
  background-repeat: no-repeat;
  box-sizing: border-box;

  ${breakpoint("xs", "xl")`
    width: 100%;
  `}
`;

export const ContentBlockInner = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  max-width: 100%;
  max-height: 100vh;

  ${breakpoint("xl")`
    //max-width: ${({ theme }) => theme.breakpoints.xl - menuWidth}px;
  `}
`;

export const TopContentWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid ${({ theme }) => theme.colors.headerBorder};

  ${breakpoint("xs")`
    padding: 36px 8px 24px 8px;
  `}

  ${breakpoint("md")`
    padding: 36px ${({ theme }) => theme.contentPadding.md}px 24px ${({ theme }) =>
      theme.contentPadding.md}px;
  `}

  ${breakpoint("lg")`
    padding: 18px ${({ theme }) => theme.contentPadding.lg}px 12px ${({ theme }) =>
      theme.contentPadding.lg}px;
  `}

  ${breakpoint("xl")`
    padding: 15px ${({ theme }) => theme.contentPadding.xl}px 15px ${({ theme }) =>
      theme.contentPadding.xl}px;
  `}

  ${breakpoint("xxl")`
    padding: 15px ${({ theme }) => theme.contentPadding.xxl}px 15px ${({ theme }) =>
      theme.contentPadding.xxl}px;
  `}
`;

export const TopContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;

  height: 40px;
  padding: ${TCVPadding}px ${TCHPadding}px;

  ${breakpoint("xs", "xlm")`
    height: 56px;
    padding: ${TCVMobilePadding}px ${TCHMobilePadding}px;
    align-items: center;
    justify-content: space-between;
    background: ${({ theme }) => theme.colors.mainMenuBg};
    border-radius: 8px;
    
    .role-${UserRole.EMPLOYEE} & {
      background: ${({ theme }) => theme.colors.mainMenuEmployeeBg};
    }
  `}
`;

export const MobileLogoContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  position: relative;

  ${breakpoint("xlm")`
    display: none;
  `}
`;

export const MLCInner = styled.div`
  background: ${({ theme }) => theme.colors.contentBg};
  display: flex;
  padding: 8px 24px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: ${mobileLogoHeight - 2}px;
  box-shadow:
    0px -10.24px 25.6px 0px ${({ theme }) => theme.colors.contentBoxShadowLight},
    0px 2.56px 7.68px 0px ${({ theme }) => theme.colors.contentBoxShadowLight};

  svg {
    width: auto;
    height: ${mobileLogoHeight}px;
  }

  ${breakpoint("xs", "md")`
    padding: 5px 18px;

    svg {
      height: ${mobileLogoHeightSmall}px;
    }
  `}
`;

export const TCInner = styled.div`
  display: flex;
  justify-content: space-between;

  ${breakpoint("xlm")`
    width: 100%;
    box-sizing: border-box;
  `}
`;

export const TCInnerBlock = styled.div``;

export const TCInnerLeft = styled(TCInnerBlock)``;

export const TCInnerRight = styled(TCInnerBlock)``;

export const MobileMenuContainer = styled.div``;

export const TCContent = styled.div`
  display: flex;
  ${breakpoint("xs", "md")`
    display: none;
  `}
`;

export const TCMobileContent = styled.div`
  display: flex;
  ${breakpoint("md")`
    display: none;
  `}

  & ${BackButtonContainer} {
    margin-bottom: 0;
  }
`;

export const Content = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 32px;
  overflow: auto;

  ${breakpoint("xs", "xlm")`
    padding: 12px;
  `}
`;

export const BreadcrumbsContainer = styled.div`
  margin-bottom: 8px;
`;

export const LoaderContent = styled(Content)`
  justify-content: center;
  align-items: center;
`;

export const Footer = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 187px;
  background-color: ${({ theme }) => theme.colors.footerBg};

  ${breakpoint("xs", "xl")`
    height: 80px;
  `}
`;

export const StyledCopyright = styled(Copyright)`
  margin-bottom: 25px;
  margin-top: 45px;

  ${breakpoint("xs", "xl")`
    width: 100%;
  `}

  ${breakpoint("xs", "lg")`
    margin: 45px 0 0 0;
    padding: 40px 80px 24px 80px;
    border-top: 1px solid ${({ theme }) => theme.colors.contentBorder};
    box-sizing: border-box;
  `}

  ${breakpoint("xs", "md")`
    padding: 40px 24px 24px 24px;
  `}
`;
